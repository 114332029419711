import React from 'react';
import './About.css';
import WebLogistian from '../../media/WebLogistian.jpg';

const About = () => {
	return (
		<div className="about-container">
			<div className="about-desc">
				<h3>Let me tell you something about me</h3>
				<p>
				I am a professional in Logistics Engineering, Web Developer, and Merchant Marine
				with great passion in Technology, as well as facing new challenges, 
				such as finding solutions to the problems faced by companies and society, 
				supporting myself with Information Technology and Supply Chain Management .
				</p>
			</div>
			<div className="about-img">
				<img src={WebLogistian} alt="about" />
			</div>
		</div>
	);
};

export default About;
