import L_HTML5 from "../../../src/media/skills/html-5.svg";
import L_CSS3 from "../../../src/media/skills/css3.svg";
import L_SASS from "../../../src/media/skills/sass-1.svg";
import L_BOOTSTRAP from "../../../src/media/skills/bootstrap-4.svg";
import L_REACT from "../../../src/media/skills/react.svg";
import L_REDUX from "../../../src/media/skills/redux.svg";
import L_REACT_ROUTER from "../../../src/media/skills/react-router.svg";
import L_MATERIALUI from "../../../src/media/skills/material-ui-1.svg";
import L_REACT_BOOTSTRAP from "../../../src/media/skills/react-bootstrap.svg";
//import L_STYLED_COMPONENTS from "../../../src/media/skills/styled-components.svg";
import L_NODE_JS from "../../../src/media/skills/nodejs.svg";
import L_EXPRESS from "../../../src/media/skills/express.svg";
//import L_DJANGO from "../../../src/media/skills/django.svg";
//import L_FLASK from "../../../src/media/skills/flask.svg";
import L_MONGODB from "../../../src/media/skills/mongodb.svg";
//import L_POSTGRESQL from "../../../src/media/skills/postgresql.svg";
//import L_MSSQL from "../../../src/media/skills/mssql.svg";
import L_GIT from "../../../src/media/skills/git-icon.svg";
import L_HEROKU from "../../../src/media/skills/heroku.svg";
//import L_DIGITAL_OCEAN from "../../../src/media/skills/digital-ocean.svg";
import L_GITHUB_PAGES from "../../../src/media/skills/github.svg";
import L_JAVASCRIPT from "../../../src/media/skills/javascript.svg";
import L_TYPESCRIPT from "../../../src/media/skills/typescript.svg";
//import L_GOLANG from "../../../src/media/skills/go-6.svg";
//import L_PYTHON from "../../../src/media/skills/python.svg";
import L_NEXT_JS from "../../../src/media/skills/nextjs-black-logo.svg";
import L_TAILWIND_CSS from "../../../src/media/skills/tailwindcss-mark.svg";
import L_VERCEL from "../../../src/media/skills/vercel.svg";
import L_FIREBASE from "../../../src/media/skills/firebase-logo-standard.svg";
import L_WORDPRESS from "../../../src/media/skills/WordPress_logo.svg";
import L_AUTOCAD from "../../../src/media/skills/Autodesk-Autocad-01.svg";
import L_REVIT from "../../../src/media/skills/Autodesk-Revit-01.svg";
import L_SOLIDWORKS from "../../../src/media/skills/SolidWorks-01.svg";
import L_SKETCHUP from "../../../src/media/skills/SketchUp-Icon-01.svg";
import L_FLEXSIM from "../../../src/media/skills/flexsim-software-products-inc-logo.svg";
import L_MICROSOFT from "../../../src/media/skills/Microsoft-Office-01.svg";
import L_C_SHARP from "../../../src/media/skills/C_Sharp.svg";
import L_CPLUSPLUS from "../../../src/media/skills/C++_Logo.svg";
import L_CISCO from "../../../src/media/skills/Cisco_logo.svg";
import L_BIZAGI from "../../../src/media/skills/Bizagi.svg";
import L_SAP from "../../../src/media/skills/SAP_2011_logo.svg";
import L_MASTERCAM from "../../../src/media/skills/MasterCAM.svg";
import L_MYSQL from "../../../src/media/skills/MYSQL.svg";



export const skills = {
  Frontend: [{
      link: "https://en.wikipedia.org/wiki/HTML5",
      imgAltText: "HTML 5",
      imgSrc: L_HTML5,
      skillName: "HTML5",
    },
    {
      link: "https://medium.com/beginners-guide-to-mobile-web-development/whats-new-in-css-3-dcd7fa6122e1",
      imgAltText: "CSS 3",
      imgSrc: L_CSS3,
      skillName: "CSS3",
    },
    {
      link: "https://www.javascript.com/",
      imgAltText: "JavaScript",
      imgSrc: L_JAVASCRIPT,
      skillName: "JavaScript",
    },
    {
      link: "https://sass-lang.com/",
      imgAltText: "Sass",
      imgSrc: L_SASS,
      skillName: "Sass",
    },
    {
      link: "https://getbootstrap.com/",
      imgAltText: "Bootstrap",
      imgSrc: L_BOOTSTRAP,
      skillName: "Bootstrap",
    },
    {
      link: "https://tailwindcss.com/",
      imgAltText: "Tailwind CSS",
      imgSrc: L_TAILWIND_CSS,
      skillName: "Tailwind CSS",
    },
    {
      link: "https://reactjs.org/",
      imgAltText: "React JS",
      imgSrc: L_REACT,
      skillName: "React JS",
    },
    {
      link: "https://redux.js.org/",
      imgAltText: "Redux",
      imgSrc: L_REDUX,
      skillName: "Redux",
    },
    {
      link: "https://reacttraining.com/react-router/",
      imgAltText: "React Router",
      imgSrc: L_REACT_ROUTER,
      skillName: "React Router",
    },
    {
      link: "https://material-ui.com/",
      imgAltText: "Material-UI",
      imgSrc: L_MATERIALUI,
      skillName: "Material-UI",
    },
    {
      link: "https://react-bootstrap.github.io/",
      imgAltText: "React Bootstrap",
      imgSrc: L_REACT_BOOTSTRAP,
      skillName: "React Bootstrap",
    },
    /*{
      link: "https://styled-components.com/",
      imgAltText: "styled-components",
      imgSrc: L_STYLED_COMPONENTS,
      skillName: "styled-components",
    },*/
  ],

  Backend: [{
      link: "https://nodejs.org/en/",
      imgAltText: "Node.js",
      imgSrc: L_NODE_JS,
      skillName: "Node.js",
    },
    {
      link: "https://expressjs.com/",
      imgAltText: "Express",
      imgSrc: L_EXPRESS,
      skillName: "Express",
    },
    {
      link: "https://nextjs.org/",
      imgAltText: "NextJS",
      imgSrc: L_NEXT_JS,
      skillName: "NextJS",
    },
    {
      link: "https://firebase.google.com/",
      imgAltText: "Firebase",
      imgSrc: L_FIREBASE,
      skillName: "Firebase",
    },
  ],
  HostingPlatforms: [{
      link: "https://www.heroku.com/",
      imgAltText: "Heroku",
      imgSrc: L_HEROKU,
      skillName: "Heroku",
    },
    {
      link: "https://vercel.com/",
      imgAltText: "Vercel",
      imgSrc: L_VERCEL,
      skillName: "Vercel",
    },
    {
      link: "https://pages.github.com/",
      imgAltText: "GitHub Pages",
      imgSrc: L_GITHUB_PAGES,
      skillName: "GitHub Pages",
    },
  ],
  CMS: [{
    link: "https://wordpress.org/",
    imgAltText: "Wordpress",
    imgSrc: L_WORDPRESS,
    skillName: "Wordpress",
  },
  ],
  ProgrammingLanguages: [{
      link: "https://www.javascript.com/",
      imgAltText: "JavaScript",
      imgSrc: L_JAVASCRIPT,
      skillName: "JavaScript",
    },
    {
      link: "https://www.typescriptlang.org/",
      imgAltText: "TypeScript",
      imgSrc: L_TYPESCRIPT,
      skillName: "TypeScript",
    },
    {
      link: "https://en.wikipedia.org/wiki/Microsoft_Visual_C%2B%2B",
      imgAltText: "C++",
      imgSrc: L_CPLUSPLUS,
      skillName: "C++",
    },
    {
      link: "https://en.wikipedia.org/wiki/C_Sharp_(programming_language)",
      imgAltText: "C#",
      imgSrc: L_C_SHARP,
      skillName: "C#",
    },
  ],
  Databases: [
    /*{
      link: "https://www.postgresql.org/",
      imgAltText: "PostgreSQL",
      imgSrc: L_POSTGRESQL,
      skillName: "PostgreSQL",
    },*/
    /*{
      link: "https://www.microsoft.com/en-us/sql-server/sql-server-2019",
      imgAltText: "MS-SQL",
      imgSrc: L_MSSQL,
      skillName: "MS-SQL",
    },*/
    {
      link: "https://www.mysql.com/",
      imgAltText: "MySQL",
      imgSrc: L_MYSQL,
      skillName: "MySQL",
    },
    {
      link: "https://www.mongodb.com/",
      imgAltText: "MongoDB",
      imgSrc: L_MONGODB,
      skillName: "MongoDB",
    },
  ],
  VersionControl: [{
    link: "https://git-scm.com/",
    imgAltText: "GIT",
    imgSrc: L_GIT,
    skillName: "GIT",
  }, ],

  Logistics: [{
      link: "https://www.autodesk.com/products/autocad/overview",
      imgAltText: "AutoCAD",
      imgSrc: L_AUTOCAD,
      skillName: "AutoCAD",
    },
    {
      link: "https://www.autodesk.com/products/revit/overview",
      imgAltText: "Revit",
      imgSrc: L_REVIT,
      skillName: "Revit",
    },
    {
      link: "https://www.solidworks.com/",
      imgAltText: "Solidworks",
      imgSrc: L_SOLIDWORKS,
      skillName: "Solidworks",
    },
    {
      link: "https://www.mastercam.com/",
      imgAltText: "MasterCAM",
      imgSrc: L_MASTERCAM,
      skillName: "MasterCAM",
    },
    {
      link: "https://www.sketchup.com/",
      imgAltText: "Sketchup",
      imgSrc: L_SKETCHUP,
      skillName: "Sketchup",
    },
    {
      link: "https://www.flexsim.com/",
      imgAltText: "Flexsim",
      imgSrc: L_FLEXSIM,
      skillName: "Flexsim",
    },
    {
      link: "https://www.bizagi.com/",
      imgAltText: "Bizagi",
      imgSrc: L_BIZAGI,
      skillName: "Bizagi",
    },
    {
      link: "https://www.office.com/",
      imgAltText: "Microsoft Office",
      imgSrc: L_MICROSOFT,
      skillName: "Microsoft Office",
    },
    {
      link: "https://www.sap.com/",
      imgAltText: "SAP",
      imgSrc: L_SAP,
      skillName: "SAP",
    },
    {
      link: "https://www.cisco.com/",
      imgAltText: "CCNA I",
      imgSrc: L_CISCO,
      skillName: "CCNA I",
    },
  ],
};