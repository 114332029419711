import React from "react";
import "./Cover.css";
import coverVideo from "../../media/coverVideo.mp4";
import Typewriter from "typewriter-effect";

const Cover = () => {
  return (
    <div className="cover-container">
      <video className="video" src={coverVideo} autoPlay loop muted />
      <h1>Glenn Bergendahl</h1>
          <Typewriter
            options={{
              strings: ["Logistics Engineer", "Web Developer", "Merchant Marine"],
              autoStart: true,
              loop: true,
              delay: 50
            }}
          />
    </div>
  );
};

export default Cover;
